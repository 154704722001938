.plans__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
}

.plan h3 {
    margin-bottom: 0.8rem;
    font-weight: 700;
    font-size: 1.7rem;
   
}

.plan small {
    margin-bottom: 2rem;
}

.plan h1 {
    color: var(--color-primary-variant);
    display: inline;
}

.plan h2 {
    display: inline;
    color: var(--color-gray-400);
}

.plan h4 {
    margin-top: 2rem;
}

.plan p {
    margin-top: 1rem;
    color: var(--color-gray-100)
}



.plan button {
    margin-top: 3rem;
    cursor: pointer;
}
article table{
    width: 100%;
    height: fit-content;
    padding-inline: auto;
    box-shadow: 1rem 1rem 3rem rgba(0,0,0,0.5);
    margin: 2rem 0 0 0;
    border-radius: 0 1.5rem 0 1.5rem;
    transition: var(--transition);
}
article table:hover{
    box-shadow: none;
}

article table tbody td{
    height: 2.8rem;
    
}
article table thead th{ 
    font-weight: 600;
    line-height: 3rem;
    background: transparent;
    overflow: hidden;
   margin: 6rem 0;
    color: var(--color-gray-100);
   
}
article table tbody{
    /* margin-top: 2.8rem; */
    padding: 1rem;
    margin-inline: auto;
    overflow: hidden;
    
}

article table tbody td{
    border-bottom: 1px solid var(--color-primary-variant);
    border-left: 1px solid var(--color-primary-variant);
   text-align: center;
   justify-content: center;
   overflow: hidden;
}
article table tbody {
   margin: 2rem;
}
article table tbody td:first-child{
    /* border-right: 1px solid var(--color-primary-variant); */
    margin-top: 6rem;
}











/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
    .plans__container {
        max-width: var(--container-width-md);
        grid-template-columns: 1fr 1fr;
       
    }
}


/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
    .plans__container {
        grid-template-columns: 1fr;
    }

    .plan {
        width: 90%;
        margin-inline: auto;
    }
}