.about__section-image {
    display: flex;
    /* border-radius: 50%; */
    border: 1rem solid transparent;
    transition: var(--transition);
   
}



.about__section-image:hover {
    border-color: var(--color-gray-500);
    
} 




.about__section-content h1 {
    margin-bottom: 2rem;
}

.about__section-content p {
    margin-bottom: 1rem;
}



.about__story-container, .about__mission-container {
    display: grid;
    grid-template-columns: 40% 50%;
    gap: 10%;
}

.about__vision-container {
    display: grid;
    grid-template-columns: 50% 40%;
    gap: 10%;
}
.footer-contact-icon.about{
    margin-top: 6px;
    /* margin-bottom: 4rem; */
}



/* MEDIA QUERIES (medium screens) */
@media screen and (max-width: 1024px) {
   section{
    margin-bottom: 5rem ;
   }

    .about__story-container, .about__vision-container, .about__mission-container {
        grid-template-columns: 1fr;  
        gap: 2rem; 
    }
    
    .about__section-image {
        width: 100%;
        margin-top: 0;
        
    }

    .about__section-content h1 {
        margin-bottom: 1.2rem;
    }

   

    .about__vision .about__section-image {
        grid-row: 1;
    }
}




/* MEDIA QUERIES (small screens) */
@media screen and (max-width: 600px) {
/* .about__story-container, .about__mission-container {  
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    } */
/* .about__section-image {
    width: 80%;
    margin-inline: auto;
} */

/* .about__story-container, .about__vision-container, .about__mission-container {
    grid-template-columns: 1fr 1fr;
} */

}