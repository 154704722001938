.container.contact__container{
    display: grid;
    grid-template-columns: 30% 50%;
    gap: 12%;
}
.contact__options{
    display: flex;
    flex-direction: column;
    gap: 0;
}
.contact__option{
    background: var(--color-bg-variant);
    padding: 0.5rem;
    text-align: center;
    transition: var(--transition);
    margin: 0;
}
.contact__option:hover{
    background: transparent;
   
}
.contact__option-icon{
    font-size: 2rem;
    margin-bottom: 0.5rem;
}
.contact__option a{
    margin-top: 0.7rem;
    display: inline-block;
    padding: 0.5rem;
   

    
}

/* =========================== EMPTY PAGES ======================================= */

.empty__page{
    margin-top: 10rem;
    display: grid;
    place-items: center;
    padding-bottom: 0;
    margin-bottom: 5rem;
    
}
.empty__page div{
    display: flex;
    gap: 2rem;
}

.contact__option-icon.merci{
    font-size: 7rem;
    margin-top: 5px;
    color: var(--color-primary);
   
}


/* ====THE FORM  ===== */
form{
    display: flex;
    flex-direction: column;
    gap:1.2rem;
    margin: 2rem 0;
}

input, textarea{
    width: 100%;
    padding:1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant) ;
    resize: none;
    color: var(--color-white);
}







/* ==================== MEDIA QUERIES (TABLET)  ======================================*/
@media screen and (max-width:1024px) {
  .container.contact__container{
    /* grid-template-columns: 1fr; */
    width: var(--container-width-md);
   
    /* gap: 2rem; */
  }
  .contact__option{
    width: max-content;
   
  }
  form{
    margin: 2rem 0 0 3rem;
  }
}
/* ==================== MEDIA QUERIES (PHONE)  ======================================*/
@media screen and (max-width:600px) {
    .container.contact__container{
         grid-template-columns: 1fr;
         width: var(--container-width-md);
         gap: 2rem;
       
      }
      form{
        margin: 0;
        
      }
      .contact__option{
        width: 100%;
       
      }

}



















/* .contact__container {
    display: grid;
    place-items: center;
}

.contact__wrapper {
    width: fit-content;
    display: flex;
    gap: 2rem;
}

.contact__wrapper a {
    background: var(--color-primary);
    font-size: 2rem;
    border: 2px solid transparent;
    transition: var(--transition);
    padding: 2rem;
    border-radius: 1.5rem;
}

.contact__wrapper a:hover {
    background: transparent;
    border-color: var(--color-gray-100);
}


/* MEDIA QUERIES (small screens) */
/*@media screen and (max-width: 600px) {
    .contact__wrapper {
        gap: 1.5rem;
    }

    .contact__wrapper a {
        padding: 1rem;
        font-size: 1.2rem;
        border-radius: 1rem;
    }
} */